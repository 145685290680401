.multi-select {
  width: 200px;
  border: 1px solid #7d7f81;
  border-radius: 4px;
}

.multi-select-title {
  color: #323334;
  font-weight: 600;
}
