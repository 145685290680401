.landing-page-header-wrapper {
  background: url('../../../public/UHG-ribbon.png');
  /* background-repeat: no-repeat; */
  background-size: 2000px 150px;
  /* position: absolute; */
  height: 150px;
  display: flex;
}

.zoom {
  zoom: 80%;
}

.content-center {
  text-align: center;
  margin-top: 40px;
}
