.date-search-column {
  width: 80%;
  margin: auto;
}

@media only screen and (min-width: 768px) {
  .date-search-column {
    width: 33%;
  }
}
